import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import 'animate.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import TestimoniesModal from 'components/modal/testimonios';

function App() {
    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </HashRouter>
            <TestimoniesModal />
        </>
    );
}

export default App;
