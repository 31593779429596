import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import ContactForm from 'components/forms/contact';
import Animated from 'components/AnimateComponent';

export default function Section_11() {
    return (
        <main className="home__section__11">
            <section className="block__title">
                <section className="block__title__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>¿Te gustaría ser atendido por uno de nuestros Agentes?</h1>
                    </Animated>
                    <section className="mg__section__container mg__section__container--relative">
                        <div className="block__title__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h2>
                                    Si tienes alguna duda o te gustaría conocer mas detalles sobre
                                    los beneficios de pertenecer a nuestra comunidad, déjanos tus
                                    datos y en breve nos comunicaremos contigo nuestra atencion es
                                    personalizada.
                                </h2>
                            </Animated>
                        </div>

                        <Animated animation={'animate__fadeInUp'}>
                            <ContactForm />
                        </Animated>
                    </section>
                </section>
            </section>
        </main>
    );
}
