import React from 'react';
import magueyTokenLogo from '../../assets/images/logos/mage-token-logo.png';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setVideo, setVideoSize } from 'reduxStore/slices/components.slice';
import Animated from 'components/AnimateComponent';

export default function Section_1() {
    const dispatch = useAppDispatch();
    return (
        <>
            <main className="home__section__1 ">
                <div className="mg__overlay mg__overlay--dark-3">
                    <section className="mg__section__container mg__section__container--relative">
                        <div className="data__container">
                            <Animated animation={'animate__fadeInUp'}>
                                <div className="home__section__1__logo">
                                    <img src={magueyTokenLogo} alt="Maguey Token Logo" />
                                </div>
                                <div className="data__container__item">
                                    <h1>Haz Crecer tu patromonio </h1>
                                    <h1> con MAGUEYTOKEN®</h1>
                                </div>

                                <div className="data__container__item">
                                    <h2>Una experiencia</h2>
                                    <h2> única en Oaxaca</h2>
                                </div>
                            </Animated>
                        </div>

                        <Animated animation={'animate__fadeInUp'}>
                            <section className="data__container__social">
                                <ActionButton
                                    text="Observa el video"
                                    type={'big clear'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        dispatch(setDisplayModal(true));
                                        dispatch(
                                            setVideo(
                                                'https://mtexperience.magueytoken.com/VIMME.mp4',
                                            ),
                                        );
                                        dispatch(setVideoSize('mg__video__full'));
                                    }}
                                />

                                <ActionButton
                                    text="¡Quiero ser parte!"
                                    type={'big'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        window.open('https://magueytoken.io/login', '_blank');
                                    }}
                                />
                            </section>
                        </Animated>
                    </section>
                </div>
            </main>
        </>
    );
}
