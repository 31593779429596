import React from 'react';
import magueyTokenLogo from 'assets/images/logos/mage-token-logo.png';
import imageNumber from 'assets/images/home/imagen_number.png';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Accordeon from 'components/acordeon';
import Animated from 'components/AnimateComponent';

export default function Section_8() {
    return (
        <main className="home__section__8">
            <section className="mg__section__container mg__section__container--relative">
                <section className="home__section__8__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>
                            Explora las oportunidades que existen en la industria del agave con
                            MAGUEYTOKEN®
                        </h1>
                    </Animated>
                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            Bienvenido a nuestra sección de preguntas frecuentes sobre la
                            emocionante industria del agave.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            En esta guía, exploraremos los aspectos clave de la contribución en la
                            industria del agave, una planta emblemática de Oaxaca que ha capturado
                            la atención de inversores y entusiastas por igual.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            El agave no es solo una planta, es una tradición arraigada en la cultura
                            mexicana y una oportunidad de crecer con un potencial impresionante.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <ActionButton
                            text="Me interesa"
                            type={'big'}
                            icon={<MdOutlineKeyboardArrowRight />}
                            onClick={() => {
                                window.open('https://magueytoken.io/login', '_blank');
                            }}
                        />
                    </Animated>
                </section>

                <section className="home__section__8__container">
                    <Animated animation={'animate__fadeInRight'}>
                        <Accordeon />
                    </Animated>
                </section>
            </section>
        </main>
    );
}
