import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';

export default function Section_5() {
    return (
        <main className="home__section__5">
            <section className="block__title">
                <section className="block__title__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>¿Te gustaría ser uno de ellos?</h1>
                    </Animated>
                    <section className="mg__section__container mg__section__container--relative">
                        <div className="block__title__info">
                            <Animated animation={'animate__fadeInUp'}>
                                <h2>
                                    Agenda una cita hoy y conoce a detalle todos los beneficios de
                                    formar parte de MAGUEYTOKEN
                                </h2>
                            </Animated>
                            <Animated animation={'animate__fadeInUp'}>
                                <ActionButton
                                    text="¡El momento es ahora!"
                                    type={'big'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        window.open('https://magueytoken.io/login', '_blank');
                                    }}
                                />
                            </Animated>
                        </div>
                    </section>
                </section>
            </section>
        </main>
    );
}
