export const patromonioData = [
    {
        title:"¡A la alza!",
        description:"Se estima que para 2032 el mercado del agave internacional alcanzará un valor de 324 mil millones de USD."
    },
    {
        title:"Oaxaca, nuestra tierra",
        description:"Oaxaca es líder en tierras fértiles para la plantación de agave y ganador del mejor destino turístico del mundo por segundo año consecutivo en los World Travel Awards."
    },
    {
        title:"Mercados Emergentes",
        description:"Los mercados internacionales están expe- rimentando un aumento en la demanda de licores premium incluyendo el mezcal."
    },
    {
        title:"¡De Oaxaca para el mundo!",
        description:"Oaxaca ocupa el 91.31 % en exportación de mezcal en el mundo afirma COMERCAM A.C."
    },
    {
        title:"Generamos empleos",
        description:"La producción de agave en Oaxaca proporciona 230 mil empleos directos impactando así en la vida de las familias oaxaqueñas."
    },
    {
        title:"Preservamos las Tradiciones",
        description:"La cosecha del maguey y la produccióndemezcal sonparte integral de la cultura de Oaxaca."
    }
]