import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';

export default function Section_9() {
    return (
        <main className="home__section__9">
            <section className="block__title">
                <section className="mg__section__container mg__section__container--relative">
                    <Animated animation={'animate__fadeInUp'}>
                        <div className="block__title__info">
                            <h1>¿Tienes otra pregunta?</h1>

                            <ActionButton
                                text="Con gusto te respondemos"
                                type={'big'}
                                icon={<MdOutlineKeyboardArrowRight />}
                                onClick={() => {
                                    window.open('https://magueytoken.io/login', '_blank');
                                }}
                            />
                        </div>
                    </Animated>
                </section>
            </section>
        </main>
    );
}
