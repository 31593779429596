import React from 'react';
import magueyTokenLogo from 'assets/images/logos/mage-token-logo.png';
import imageNumber from 'assets/images/home/imagen_number.png';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';

export default function Section_6() {
    return (
        <main className="home__section__6">
            <section className="mg__section__container mg__section__container--relative">
                <section className="home__section__6__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <div className="home__section__6__logo">
                            <img src={magueyTokenLogo} alt="Maguey Token Logo" />
                        </div>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <h1>¡Potencia tu futuro con nuestras plantaciones!</h1>
                    </Animated>
                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            Explora una emocionante oportunidad de formar parte de la industria del
                            mezcal, una bebida que esta enamorando al mundo.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            Nuestros cultivos de agave ofrecen una manera única de crecer tu dinero
                            y obtener los mayores rendimientos.
                        </p>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <p>
                            Con opciones para todos, encontrarás la oportunidad perfecta para tus
                            objetivos financieros.
                        </p>
                    </Animated>
                </section>

                <section className="home__section__6__container">
                    <Animated animation={'animate__fadeInDown'}>
                        <h4>Empieza hoy desde:</h4>
                    </Animated>
                    <Animated animation={'animate__fadeInUp'}>
                        <div className="image__number">
                            <img src={imageNumber} alt="Image Number" />
                        </div>
                    </Animated>
                    <Animated animation={'animate__fadeInUp'}>
                        <h5>Y disfruta de todos los beneficios de formar parte de MAGUEYTOKEN</h5>
                    </Animated>

                    <Animated animation={'animate__fadeInUp'}>
                        <ActionButton
                            text="¡Comienza a crecer ya!"
                            type={'big'}
                            icon={<MdOutlineKeyboardArrowRight />}
                            onClick={() => {
                                window.open('https://magueytoken.io/login', '_blank');
                            }}
                        />
                    </Animated>
                </section>
            </section>
        </main>
    );
}
