import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import SquareInfo from 'components/square-info';
import { patromonioData } from 'data/patromonio';
import Animated from 'components/AnimateComponent';

export default function Section_3() {
    return (
        <main className="home__section__3">
            <section className="mg__section__container mg__section__container--relative">
                <section className="header">
                    <div className="header__titles">
                        <Animated animation={'animate__fadeInUp'}>
                            <h1>APROVECHA ESTA TENDENCIA </h1>
                            <h1>Y CRECE TU PATRIMONIO</h1>
                            <h2>Descubre las oportunidades del agave en Oaxaca</h2>
                        </Animated>
                    </div>
                    <div className="header__contact text-center">
                        <Animated animation={'animate__fadeInUp'}>
                            <h4>
                                Las tendencias de la industria del agave son más prometedoras de lo
                                que tu piensas.
                            </h4>
                            <h2>¿Quieres conocer más?</h2>

                            <ActionButton
                                text="Agenda una cita aquí"
                                type={'big'}
                                icon={<MdOutlineKeyboardArrowRight />}
                                onClick={() => {
                                    window.open('https://magueytoken.io/login', '_blank');
                                }}
                            />
                        </Animated>
                    </div>
                </section>

                <section className="content">
                    <main className="mg__sqareInfo">
                        {patromonioData.map((item, index) => {
                            return (
                                <SquareInfo
                                    key={index}
                                    title={item.title}
                                    description={item.description}
                                />
                            );
                        })}
                    </main>
                </section>
            </section>
        </main>
    );
}
