import React, { ReactElement } from 'react';

interface ActionButtonProps {
    text: string;
    onClick: () => void;
    icon?: ReactElement;
    type: string;
}
export default function ActionButton({ text, onClick, icon, type }: ActionButtonProps) {
    return (
        <>
            {type === 'rounded' && (
                <button className="MT__button MT__button--rounded transition" onClick={onClick}>
                    {text} {icon}
                </button>
            )}

            {type === 'normal' && (
                <button className="MT__button MT__button--normal transition" onClick={onClick}>
                    {text} {icon}
                </button>
            )}

            {type === 'normal clear' && (
                <button
                    className="MT__button MT__button--normal-clear transition"
                    onClick={onClick}
                >
                    {text} {icon}
                </button>
            )}

            {type === 'big' && (
                <button className="MT__button MT__button--big transition" onClick={onClick}>
                    {text} {icon}
                </button>
            )}

            {type === 'big clear' && (
                <button className="MT__button MT__button--big-clear transition" onClick={onClick}>
                    {text} {icon}
                </button>
            )}
        </>
    );
}
