import React from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import magueyTokenLogo from 'assets/images/logos/mage-token-logo.png';
import ActionButton from 'components/buttons/action';
import Animated from 'components/AnimateComponent';

export default function Section_2() {
    return (
        <main className="home__section__2 bg__paralax">
            <div className="mg__overlay mg__overlay--dark-4">
                <section className="mg__section__container mg__section__container--relative">
                    <section className="home__section__2__container">
                        <Animated animation={'animate__fadeInUp'}>
                            <div className="home__section__2__logo">
                                <img src={magueyTokenLogo} alt="Maguey Token Logo" />
                            </div>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <p>
                                ¿Estás buscando oportunidades emocionantes para hacer crecer tu
                                economía mientras contribuyes al desarrollo sostenible?
                            </p>
                        </Animated>

                        <Animated animation={'animate__fadeInUp'}>
                            <p>
                                MAGUEYTOKEN® te ofrece una nueva forma de participar en la
                                industria de las Bebidas Spirits a través de plantaciones de agave
                                en el estado de Oaxaca.
                            </p>
                        </Animated>
                        <Animated animation={'animate__fadeInUp'}>
                            <ActionButton
                                text="¡Quiero ser parte!"
                                type={'big'}
                                icon={<MdOutlineKeyboardArrowRight />}
                                onClick={() => {
                                    window.open('https://magueytoken.io/login', '_blank');
                                }}
                            />
                        </Animated>
                    </section>
                </section>

                <div className="home__section__2__textInfo">
                    <Animated animation={'animate__fadeInRight'}>
                        <h2>Te Invitamos a </h2>

                        <h1>Ser parte</h1>

                        <h2>de la insdustria</h2>

                        <h1>del Agave</h1>
                    </Animated>
                </div>
            </div>
        </main>
    );
}
