import React from 'react';
import Section_1 from './section.1';
import Section_2 from './section.2';
import Section_3 from './section.3';
import Section_4 from './section.4';
import Section_5 from './section.5';
import Section_6 from './section.6';
import Section_7 from './section.7';
import Section_8 from './section.8';
import Section_9 from './section.9';
import Section_10 from './section.10';
import Section_11 from './section.11';
import Footer from './footer';

export default function HomePage() {
    return (
        <>
            <main className="main__page main__page--full home__page">
                <Section_1 />
                <Section_2 />
                <Section_3 />
                <Section_4 />
                <Section_5 />
                <Section_6 />
                <Section_7 />
                <Section_8 />
                <Section_9 />
                <Section_10 />
                <Section_11 />
            </main>
            <Footer />
        </>
    );
}
