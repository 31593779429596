import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import Animated from 'components/AnimateComponent';

export default function Section_7() {
    return (
        <main className="home__section__7">
            <section className="block__title">
                <section className="mg__section__container mg__section__container--relative">
                    <div className="block__title__info">
                        <Animated animation={'animate__fadeInUp'}>
                            <h2>Iniciarse en la industria del agave nunca fué tan fácil</h2>
                        </Animated>
                        <Animated animation={'animate__fadeInRight'}>
                            <ActionButton
                                text="Agenda una cita aquí"
                                type={'big'}
                                icon={<MdOutlineKeyboardArrowRight />}
                                onClick={() => {
                                    window.open('https://magueytoken.io/login', '_blank');
                                }}
                            />
                        </Animated>
                    </div>
                </section>
            </section>
        </main>
    );
}
