import React from 'react';
import Animated from 'components/AnimateComponent';

interface SquareInfoProps {
    title: string;
    description: string;
}
export default function SquareInfo({ title, description }: SquareInfoProps) {
    return (
        <section className="mg__sqareInfo__item">
            <Animated animation={'animate__fadeInUp'}>
                <div className="mg__sqareInfo__item__content">
                    <h3 className="mg__sqareInfo__item__title">{title}</h3>
                    <p>{description}</p>
                </div>
            </Animated>
        </section>
    );
}
