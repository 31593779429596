import Starts from 'components/starts';
import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

interface comunityProps {
    item: any;
}

export default function ComunityMobile({ item }: comunityProps) {
    return (
        <main className="mg__comunity">
            <section className="mg__comunity__item">
                <>
                    <div className="mg__comunity__item__block mg__comunity__item__block--left">
                        <div
                            className="mg__comunity__item__image"
                            style={{ backgroundImage: `url(${item.image})` }}
                        >
                            <div className="btn__position btn__position--right ">
                                <ActionButton
                                    text="Observa el video"
                                    type={'big'}
                                    icon={<MdOutlineKeyboardArrowRight />}
                                    onClick={() => {
                                        console.log('click');
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mg__comunity__item__block mg__comunity__item__block--right">
                        <div className="mg__comunity__item__info">
                            <div className="mg__comunity__item__info__container">
                                <div className="mg__comunity__item__name">
                                    <h1>{item.name}</h1>
                                    <Starts />
                                </div>

                                <h3>{item.subtitle}</h3>

                                <p>{item.description}</p>
                            </div>
                        </div>
                    </div>
                </>
            </section>
        </main>
    );
}
