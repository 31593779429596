import Starts from 'components/starts';
import React from 'react';
import ActionButton from 'components/buttons/action';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useAppDispatch } from 'reduxStore/hooks';
import { setDisplayModal, setVideo, setVideoSize } from 'reduxStore/slices/components.slice';
import Animated from 'components/AnimateComponent';

interface comunityProps {
    item: any;
}

export default function ComunityComponente({ item }: comunityProps) {
    const dispatch = useAppDispatch();
    return (
        <main className="mg__comunity">
            <section className="mg__comunity__item">
                {item.position === 'left' ? (
                    <>
                        <div className="mg__comunity__item__block mg__comunity__item__block--left">
                            <Animated animation={'animate__fadeInLeft'}>
                                <div
                                    className="mg__comunity__item__image"
                                    style={{ backgroundImage: `url(${item.image})` }}
                                >
                                    <div className="btn__position btn__position--right ">
                                        <ActionButton
                                            text="Observa el video"
                                            type={'big'}
                                            icon={<MdOutlineKeyboardArrowRight />}
                                            onClick={() => {
                                                dispatch(setDisplayModal(true));
                                                dispatch(setVideo(item.video));
                                            }}
                                        />
                                    </div>
                                </div>
                            </Animated>
                        </div>

                        <div className="mg__comunity__item__block mg__comunity__item__block--right">
                            <Animated animation={'animate__fadeInRight'}>
                                <div className="mg__comunity__item__info">
                                    <div className="mg__comunity__item__info__container">
                                        <div className="mg__comunity__item__name">
                                            <h1>{item.name}</h1>
                                            <Starts />
                                        </div>

                                        <h3>{item.subtitle}</h3>

                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </Animated>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mg__comunity__item__block mg__comunity__item__block--right">
                            <Animated animation={'animate__fadeInLeft'}>
                                <div className="mg__comunity__item__info">
                                    <div className="mg__comunity__item__info__container">
                                        <div className="mg__comunity__item__name mg__comunity__item__name--right">
                                            <Starts />
                                            <h1 className="text-right">{item.name}</h1>
                                        </div>

                                        <h3 className="text-right">{item.subtitle}</h3>

                                        <p>{item.description}</p>
                                    </div>
                                </div>
                            </Animated>
                        </div>

                        <div className="mg__comunity__item__block mg__comunity__item__block--left">
                            <Animated animation={'animate__fadeInRight'}>
                                <div
                                    className="mg__comunity__item__image"
                                    style={{ backgroundImage: `url(${item.image})` }}
                                >
                                    <div className="btn__position btn__position--left ">
                                        <ActionButton
                                            text="Observa el video"
                                            type={'big'}
                                            icon={<MdOutlineKeyboardArrowRight />}
                                            onClick={() => {
                                                dispatch(setDisplayModal(true));
                                                dispatch(setVideo(item.video));
                                            }}
                                        />
                                    </div>
                                </div>
                            </Animated>
                        </div>
                    </>
                )}
            </section>
        </main>
    );
}
