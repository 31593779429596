import React from 'react';
import { comunidadData } from 'data/comunidad';
import ComunityComponente from 'components/comunidad';
import ComunityMobile from 'components/comunidad/mobile';
import Animated from 'components/AnimateComponent';

export default function Section_4() {
    return (
        <main className="home__section__4">
            <section className="block__title">
                <section className="block__title__container">
                    <Animated animation={'animate__fadeInUp'}>
                        <h1>Conoce a nuestra comunidad</h1>
                        <h4>
                            Escucha las experiencias de algunas de las personas que han confiado en
                            nosotros.
                        </h4>
                    </Animated>
                </section>
            </section>
            <section className="mg__section__container mg__section__container--relative mg__comunity__web">
                {comunidadData.map((item, index) => {
                    return <ComunityComponente item={item} />;
                })}
            </section>

            <section className="mg__section__container mg__section__container--relative mg__comunity__mobile">
                {comunidadData.map((item, index) => {
                    return <ComunityMobile item={item} />;
                })}
            </section>
        </main>
    );
}
