
import comunidadImg1 from "assets/images/comunidad/comunidad-1.png";
import comunidadImg2 from "assets/images/comunidad/comunidad-2.png";
import comunidadImg3 from "assets/images/comunidad/comunidad-3.png";
import comunidadImg4 from "assets/images/comunidad/comunidad-4.png";
import video1 from "assets/videos/comunidad/video_1.mp4";
import video2 from "assets/videos/comunidad/video_2.mp4";
import video3 from "assets/videos/comunidad/video_3.mp4";
import video4 from "assets/videos/comunidad/video_4.mp4";

export const comunidadData = [
    {
        name:"Luisa",
        subtitle:"Me pareció una idea excelente.",
        description:"Aunque no conozco mucho sobre el mezcal, la propuesta me pareció muy buena. El sistema que están manejando parece muy eficaz, y todo lo que proponen tendrá resultados a mediano plazo, ¡pero serán resultados fantásticos!",
        image:comunidadImg1,
        video:video1,
        position:"left"
    },

    {
        name:"JAVIER",
        subtitle:"Oaxaca reune todas las condiciones para desarrollar este tipo de proyectos.",
        description:"El profesionalismo con el que nos tratan desde la primera llamada se nota. Es una empresa consolidada y que tienen bien clara su visión, su meta y sobre todo tienen bien claro como lo van a lograr. Esa idea la compras desde el primer instante que tienes comunicación con ellos.",
        image:comunidadImg2,
        video:video2,
        position:"right"

    },

    {
        name:"JULIA Y DAVID",
        subtitle:"Estamos muy contentos con la decisión que tomamos.",
        description:"Creemos que es importante ayudar a preservar el tema del maguey y el mezcal, y así asegurar un patrimonio y un rendimiento para el futuro de nuestros hijos. Además, nos han tratado de maravilla y los eventos han sido geniales. Muchas gracias por todo.",
        image:comunidadImg3,
        video:video3,
        position:"left"
    },
    {
        name:"ROBERTO",
        subtitle:"Queremos agradecer a MAGUEYTOKEN por la invitación.",
        description:"Aunque existe un riesgo, es prácticamente mínimo al ver toda la infraestructura y la administración que tienen sobre los campos de cultivo. Invito a todos a que se sumen, pues esto es parte de nuestra tierra y de México.",
        image:comunidadImg4,
        video:video4,
        position:"right"
    }

]